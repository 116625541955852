import React from 'react'
import { Container } from 'react-bootstrap'
import { textStyle, containerImageCover, backgroundStyle } from '../../utils'
import parse from 'html-react-parser'

export const CodeModule = ({ htmlText, backgroundImage, style }) => {
  return (
    <Container
      fluid
      className="banner-module"
      css={[
        containerImageCover(backgroundImage?.file.url),
        backgroundStyle(style?.backgroundColor),
        textStyle(style?.textColor),
      ]}
    >
      <div className="code-module-outer mx-auto">
        {htmlText && parse(htmlText.htmlText)}
      </div>
    </Container>
  )
}

export const mapCodeModuleProps = props => props
